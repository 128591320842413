<template>
  <div v-on:keyup.enter="signUp">
    <div class="text-center auth_title mb-10">
      {{ $tr("auth", "key_3") }}
    </div>
     <v-text-field
        :label="$tr('auth', 'key_23')"
        outlined
        color="primary"
        type="text"
        v-model="first_name"
    ></v-text-field>
    <v-text-field
        :label="$tr('auth', 'key_24')"
        outlined
        color="primary"
        type="text"
        v-model="last_name"
    ></v-text-field>
    <v-text-field
        :label="$tr('auth', 'key_5')"
        outlined
        color="primary"
        type="email"
        v-model="email"
    ></v-text-field>
    <v-text-field
        :label="$tr('auth', 'key_6')"
        outlined
        color="primary"
        :type="!showPassword ? 'password' : 'text'"
        v-model="password"
        v-on:keyup="checkValidation()"
        v-on:change="hideValidation()"
    >
      <template v-slot:append>
        <v-btn
            icon
            width="40"
            depressed
            height="40"
            color="smoke"
            @click="showPassword = !showPassword"
        >
          <v-icon v-text="!showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"/>
        </v-btn>
      </template>
    </v-text-field>
    <p v-if="regex" :class="color" style="margin-bottom:10px; font-size:12px;">(Password must be contain with minimum 8 characters, combination of one upper-case, lower-case, and number.)</p>

    <v-text-field
        :label="$tr('auth', 'key_10')"
        outlined
        color="primary"
        :type="!showRePassword ? 'password' : 'text'"
        v-model="rePassword"
    >
  
      <template v-slot:append>
        <v-btn
            icon
            width="40"
            depressed
            height="40"
            color="smoke"
            @click="showRePassword = !showRePassword"
        >
          <v-icon v-text="!showRePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"/>
        </v-btn>
      </template>
    </v-text-field>
    <div class="caption text-center smoke--text align-center mb-2 d-flex">
      <!-- {{ $tr("auth", "key_11") }} -->
                        <v-checkbox
                          color="primary"
                          :true-value="true"
                          :false-value="false"
                          :value="true"
                          :input-value="term_file === true"
                          @change="checkTerms"
                      ></v-checkbox>
                      <div class="mt-4">
                        By signing up you agree to our <a  target="_blank" href="https://www.webtomob.com/terms.html">Terms of Service</a> and <a target="_blank" href="https://www.webtomob.com/policy.html">Privacy Policy</a>.
                      </div>
    </div>
    <v-btn
        x-large
        depressed
        color="primary"
        block
        :loading="loading"
        :disabled="!term_file"
        @click="signUp"
    >
      {{ $tr("auth", "key_15") }}
    </v-btn>
    <!-- <template v-if="$store.state.config.google.enabled === true">
      <GoogleIdentity :key-id="$store.state.config.google.id"  @callback="startGoogleLogin"/>
    </template> -->
    <template>
      <GoogleIdentity :key-id="$store.state.config.google.id"  @callback="startGoogleLogin"/>
    </template>
      
    <div class="text-center mb-5 mt-5">
      <router-link to="/">
        {{ $tr("auth", "key_16") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import GoogleIdentity from "@/components/blocks/GoogleIdentity";

export default {
  name: 'Register',
  components: {
    GoogleIdentity
  },
  data: () => ({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    rePassword: "",
    showPassword: false,
    showRePassword: false,
    loading: false,
    term_file: false,
    regex : false,
    color: 'red',
  }),
  methods: {
    checkValidation(){
      const regexpassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
      if (!regexpassword.test(this.password)){
       this.regex = true;
       this.color = "red";
      }else{
        this.color = "green";
      }
    },
    hideValidation(){
      this.regex = false;
    },
    checkTerms() {
      this.term_file = this.term_file === true ? false : true;
    },
    signUp() {
      this.loading = true;
      let params = new URLSearchParams();
      params.append('first_name', this.first_name);
      params.append('last_name', this.last_name);
      params.append('email', this.email );
      params.append('password', this.password );
      params.append('re_password', this.rePassword );
      this.$http.post(`${this.$serverApiLink}api/auth/sign_up`, params)
      .then(
          response => {
            // this.$store.commit('setUser', response.data.user);
            this.$store.commit('setSnackBar', {
              code: 200,
              message: response.data.message
            });
            // this.loading = false;
            this.$router.push({name: 'Login'});
            // this.$router.push({name: 'Apps'});
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    startGoogleLogin(tokenID) {
      this.$store.commit('setLoading', true);
      let params = new URLSearchParams();
      params.append('id_token', tokenID );
      this.$http.post(`${this.$serverApiLink}api/auth/google_in`, params)
          .then(
              response => {
                this.$store.commit('setUser', response.data.user);
                this.$store.commit('setLoading', false);
                this.$router.push({name: 'Apps'});
              }
          ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.$store.commit('setLoading', false);
          }
      );
    }
  },
  mounted() {

  }
}
</script>
<style scoped>
.green {
  color:green;
  background-color: unset !important;
  border-color: unset !important;
}
.red {
  color: red;
  background-color: unset !important;
  border-color: unset !important;
}
</style>